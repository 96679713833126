<ng-container *ngIf="showInfoBanner">
  <div class="info-banner center-block" *ngFor="let insurer of insuredInfo">
    <div
      class="info-banner-header"
      role="tab"
      [ngClass]="{ expanded: showExpansion }"
    >
      <div class="flex">
        <div class="quote-info">
          <div class="quote-title">
            <boxxds-heading
              [sz]="'6'"
              [text]="infoTitle"
              type="bold"
            ></boxxds-heading>
            <div class="quote-id">
              <p>
                {{ "common.quoteId" | translate }}
                {{ quoteId === 0 ? "-" : quoteId }}
              </p>
            </div>
          </div>

          <div class="flex-wrap collapse-btn" (click)="expandInfoBanner()">
            <img src="assets/img/dashboard/quote/chevron-up.png" alt="" />
          </div>
        </div>

        <div class="insured-info" *ngFor="let broker of brokerageInfo">
          <!-- col 1 -->
          <div class="flex-wrap flex-insured">
            <div class="insured-div div-label first-div">
              {{ "quote4Flow.insured" | translate }}
            </div>
            <div class="insured-div div-label">
              {{ "quote4Flow.industry" | translate }}
            </div>
            <div class="insured-div div-label">
              {{ "quote4Flow.annualRevenue" | translate }}
            </div>
            <div class="insured-div div-label">
              {{ "quote4Flow.brokerage" | translate }}
            </div>

            <div class="insured-div div-value first-div">
              <a
                class="insured-link"
                [routerLink]="getNavigationUrl(insurer.insuredId)"
                skipLocationChange="true"
                [ngStyle]="{ textDecoration: 'none' }"
              >
                <p [title]="insurer.companyName">{{ insurer.companyName }}</p>
              </a>
            </div>
            <div class="insured-div div-value second">
              <p [title]="insurer.industry">{{ insurer.industry }}</p>
            </div>
            <div
              class="insured-div div-value second"
              [title]="
                insurer.revenue
                  | currencyFormat: currency : 'symbol-narrow' : '1.0-0'
              "
            >
              {{
                insurer.revenue
                  | currencyFormat: currency : "symbol-narrow" : "1.0-0"
              }}
            </div>
            <div class="insured-div div-value second">
              <p [title]="broker.brokerage">{{ broker.brokerage }}</p>
            </div>
          </div>

          <!-- col 2 -->
          <div class="flex-wrap flex-insured flex-body" *ngIf="showExpansion">
            <div class="insured-div div-label first-div">
              {{ "quote4Flow.industryClass" | translate }}
            </div>
            <div class="insured-div div-label">
              {{ "quote4Flow.employeeCount" | translate }}
            </div>
            <div class="insured-div div-label">
              {{ "quote4Flow.contactDetails" | translate }}
            </div>
            <div class="insured-div div-label">
              {{ "quote4Flow.producer" | translate }}
            </div>

            <div class="insured-div div-value first-div">
              <p [title]="insurer.industryClass">{{ insurer.industryClass }}</p>
            </div>
            <div
              class="insured-div div-value"
              [title]="
                insurer.numOfEmployees && insurer.numOfEmployees !== ''
                  ? insurer.numOfEmployees
                  : '-'
              "
            >
              {{
                insurer.numOfEmployees && insurer.numOfEmployees !== ""
                  ? insurer.numOfEmployees
                  : "-"
              }}
            </div>
            <div class="insured-div div-value">
              <p [title]="insurer.fullAddress">{{ insurer.fullAddress }}</p>
            </div>
            <div class="insured-div div-value">
              <p [title]="broker.producer">{{ broker.producer }}</p>
            </div>
          </div>
        </div>

        <div class="notes-info" *ngIf="quoteId">
          <div
            class="notes-div"
            [ngClass]="{ notify: hasNotifications }"
            (click)="handleNotesPopup($event)"
          >
            <label> Notes </label>
            <img src="assets/img/notes.png" alt="" />
          </div>

          <app-notes-v2
            *ngIf="showModalNotesPopup"
            [showModalNotesPopup]="showModalNotesPopup"
            [policyPeriodId]="policyPeriodId"
            (handleClose)="handleNotesPopupClose($event)"
          ></app-notes-v2>
        </div>
      </div>
    </div>
  </div>
</ng-container>
